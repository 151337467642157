import {
  Card,
  Headline,
  Icon,
  Spinner,
  Stack,
  Frame,
} from "@servicetitan/design-system";
import { useRef } from "react";
import { ReactComponent as Logo } from "../../assets/stLogo.svg";

import { useMountWidget } from "../../hooks/useMountWidget";
import { getWidgetUrl } from "../../utils/getWidgetUrl";
import {
  CardBodyText,
  Footer,
  FrameItem,
  LocationText,
  StyledFooterText,
  StyledIFrame,
} from "../../shared/styled";
import { DesktopCardBanner, FrameStackItem } from "./styled";
import { ILandingPageProps } from "../../types/shared";
import { Header } from "../Header";
import { WIDGET_BOX_SHADOW_SIZE_PX } from "../../shared/constants";

export const DesktopLandingPage = (props: ILandingPageProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const { addressLine, name } = props;

  const isV2 = "schedulerSrc" in props;

  const isCardVisible = useMountWidget(
    iframeRef,
    isV2 ? undefined : props.clientKey,
    isV2 ? props.schedulerSrc : undefined
  );

  return (
    <Frame>
      <Stack className="w-100 h-100" direction="column">
        <Stack alignItems="center" justifyContent="center" ref={headerRef}>
          <Stack alignItems="center" direction="column" justifyContent="center">
            <Header
              googleBusinessProfileMediaUrl={props.mediaUrl || ""}
              googleBusinessProfileName={name}
              style={{ maxHeight: "90px", maxWidth: "100%" }}
            />
            <Stack alignItems="center" className="p-t-2">
              <Icon name="location_on" size={25} />
              <LocationText
                className="p-l-half"
                data-cy="gbp-location"
                size="medium"
              >
                {addressLine}
              </LocationText>
            </Stack>
          </Stack>
        </Stack>
        {isCardVisible && (
          <Stack
            alignItems="center"
            className="w-100 h-100 m-t-5"
            direction="column"
          >
            <Card padding="none">
              <DesktopCardBanner />
              <Stack
                alignItems="center"
                direction="column"
                justifyContent="center"
              >
                <Headline>Book a Service Appointment</Headline>
                <CardBodyText className="p-b-2" inline={true}>
                  Book a new appointment or pick up where <br /> you left off.
                </CardBodyText>
                <Spinner className="p-y-2" size="small" />
              </Stack>
            </Card>
          </Stack>
        )}
        <FrameItem>
          <Stack
            alignItems="center"
            className="w-100 h-100"
            direction="column"
            justifyContent="center"
          >
            <FrameStackItem
              absolute={!isV2}
              isVisible={!isCardVisible}
              top={
                isV2
                  ? WIDGET_BOX_SHADOW_SIZE_PX
                  : headerRef?.current?.offsetHeight ?? 155
              }
            >
              <StyledIFrame
                data-cy="iframe"
                isDesktopV2={isV2}
                isVisible={!isCardVisible}
                ref={iframeRef}
                src={getWidgetUrl(
                  isV2
                    ? {
                        schedulerSrc: props.schedulerSrc,
                      }
                    : {
                        clientKey: props.clientKey,
                        flowKey: props.flowKey || "",
                      }
                )}
                title="Widget"
              ></StyledIFrame>
            </FrameStackItem>
          </Stack>
        </FrameItem>
        <Footer className="p-t-2 m-t-auto">
          <Stack className="m-b-0 w-100" justifyContent="center">
            <Stack direction="column">
              <Stack justifyContent="center">
                <StyledFooterText className="m-b-1" size="small">
                  Powered By
                </StyledFooterText>
              </Stack>
              <Logo viewBox="80 0 70 70" />
            </Stack>
          </Stack>
        </Footer>
      </Stack>
    </Frame>
  );
};
