import {
  Card,
  Headline,
  Icon,
  Spinner,
  Stack,
  Frame,
} from "@servicetitan/design-system";
import { useRef } from "react";
import { ReactComponent as Logo } from "../../assets/stLogo.svg";
import { ReactComponent as CardBanner } from "../../assets/cardBanner.svg";
import { useMountWidget } from "../../hooks/useMountWidget";
import { getWidgetUrl } from "../../utils/getWidgetUrl";
import {
  CardBodyText,
  Footer,
  FrameItem,
  LocationText,
  StyledIFrame,
} from "../../shared/styled";
import { FooterText, FrameStackItem } from "./styled";
import { ILandingPageProps } from "../../types/shared";
import { Header } from "../Header";

export const MobileLandingPage = (props: ILandingPageProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { addressLine, name } = props;

  const isV2 = "schedulerSrc" in props;

  const isCardVisible = useMountWidget(
    iframeRef,
    isV2 ? undefined : props.clientKey,
    isV2 ? props.schedulerSrc : undefined
  );

  return (
    <Frame
      header={
        <Stack alignItems="center" justifyContent="center">
          <Stack alignItems="center" direction="column" justifyContent="center">
            <Header
              googleBusinessProfileMediaUrl={props.mediaUrl || ""}
              googleBusinessProfileName={name}
              style={{ maxHeight: "55px", maxWidth: "173px" }}
            />
            <Stack alignItems="center" className="p-t-1">
              <Icon name="location_on" />
              <LocationText
                className="p-l-1"
                data-cy="gbp-location"
                size="xsmall"
              >
                {addressLine}
              </LocationText>
            </Stack>
          </Stack>
        </Stack>
      }
    >
      <Stack className="w-100 h-100" direction="column">
        {isCardVisible && (
          <Stack.Item className="m-t-1">
            <Stack
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <Card elevation="base" padding="none">
                <CardBanner />
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                >
                  <Headline className="m-t-2" size="medium">
                    Book a Service Appointment
                  </Headline>
                  <CardBodyText className="p-b-2" inline={true} size="medium">
                    Book a new appointment or pick up where <br /> you left off.
                  </CardBodyText>
                  <Spinner className="p-y-2" size="small" />
                </Stack>
              </Card>
            </Stack>
          </Stack.Item>
        )}
        <FrameItem>
          <Stack
            alignItems="center"
            className={!isCardVisible ? "w-100 h-100" : "w-0 h-0"}
            direction="column"
            justifyContent="center"
          >
            <FrameStackItem>
              <StyledIFrame
                data-cy="iframe"
                isVisible={!isCardVisible}
                ref={iframeRef}
                src={getWidgetUrl(
                  isV2
                    ? {
                        schedulerSrc: props.schedulerSrc,
                      }
                    : {
                        clientKey: props.clientKey,
                        flowKey: props.flowKey || "",
                      }
                )}
                title="Widget"
              ></StyledIFrame>
            </FrameStackItem>
          </Stack>
        </FrameItem>
        <Footer className="p-t-2 m-t-auto">
          <Stack className="m-b-0 w-100" justifyContent="center">
            <Stack direction="column">
              <Stack justifyContent="center">
                <FooterText className="m-b-0" size="small">
                  Powered By
                </FooterText>
              </Stack>
              <Logo viewBox="80 0 70 70" />
            </Stack>
          </Stack>
        </Footer>
      </Stack>
    </Frame>
  );
};
